<script>
export default {
	name: "PopupTime",
	props:{
		isActivePopupTime:{
			type: Boolean,
			required: true,
		}
	},
	computed: {
	},
	data(){
		return{
		}
	},
	methods: {
		closePopup(){
			// console.log("closePopup")
			let vm=this
			vm.$emit("update:isActivePopupTime", false);
		},
	},
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>