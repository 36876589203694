<script>
import { nextTick } from "vue";
import lottie from "lottie-web";
import arrow from "@/assets/json/arrow-blue.json";
import circle from "@/assets/json/yellow-circle.json";
export default {
	name: "Home",
	computed: {
		allPoints(){
			return this.$store.state.allPoints
		},
	},
	data(){
		return{
			aniArrow: "",
			aniCircle: "",
			isEndInvoice: false
		}
	},
	watch:{
		allPoints(){
			let vm=this

			let progessLine=vm.allPoints*1.1/100
			if(progessLine>100){
				progessLine=100
			}
			document.documentElement.style.setProperty("--progessLine", `${progessLine}%`);
		}
	},
	mounted() {
		nextTick(() => {
			let vm = this;

			let start = "2023-9-16 00:00";
			let now = new Date();
			let reg = new RegExp("-", "g");

			if (new Date(start.replace(reg, "/")) <= now) {
				vm.isEndInvoice=true
			}

			vm.aniArrow = lottie.loadAnimation({
				container: document.getElementById("aniArrow"),
				animationData: arrow,
				renderer: "svg",
				loop: true,
				autoplay: true, 
				name: "aniArrow", 
			});
			vm.aniCircle = lottie.loadAnimation({
				container: document.getElementById("aniCircle"),
				animationData: circle,
				renderer: "svg",
				loop: true,
				autoplay: true, 
				name: "aniCircle", 
			});
		});
	},
	beforeUnmount(){
		let vm = this;
		vm.aniCircle.destroy()
		vm.aniArrow.destroy()
	}
}
</script>
<template lang="pug" src="./template.pug"></template>
<style lang="sass" scoped src="./style.sass"></style>