<script>
export default {
	name: "Info",
	props:{
		isActiveInfo:{
			type: Boolean,
			required: true,
		}
	},
	computed: {
	},
	data(){
		return{
		}
	},
	methods: {
		closePopup(){
			// console.log("closePopup")
			let vm=this
			vm.$store.commit("infoToggle")
		}
	},
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>