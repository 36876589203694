import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home/index.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: "/giftlist",
		name: "GiftList",
		component: () => import("@/views/giftlist/index.vue"),
	},
	{
		path: "/giftlist/:id",
		component: () => import("@/views/giftdetail/index.vue"),
	},
	{
		path: "/giftform",
		name: "GiftForm",
		component: () => import("@/views/giftform/index.vue"),
	},
	{
		path: "/invoicechoose",
		name: "InvoiceChoose",
		component: () => import("@/views/invoicechoose/index.vue"),
	},
	{
		path: "/invoiceform",
		name: "InvoiceForm",
		component: () => import("@/views/invoiceform/index.vue"),
	},
	{
		path: "/share",
		name: "Share",
		component: () => import("@/views/share/index.vue"),
	},
	{
		path: "/record",
		name: "Record",
		component: () => import("@/views/record/index.vue"),
	},,
	{
		path: "/store",
		name: "Store",
		component: () => import("@/views/store/index.vue"),
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/",
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { top: 0 }
	},
})

export default router
