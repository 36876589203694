<script>
export default {
	name: "Userinfo",
	computed: {
		userLineInfo(){
			return this.$store.state.userLineInfo
		},
		userNTInfo(){
			return this.$store.state.userNTInfo
		},
	},
	methods:{
		removeFloat(points){
			return  points.toString().split(".")[0]
		}
	}
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>