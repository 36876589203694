import { createStore } from 'vuex'
import liff from "@line/liff";
import addressData from "@/assets/json/addressData.json";
import axios from "axios";
import router from '@/router'

export default createStore({
	devtools: true,
	strict: true,
	state: {
		isActiveLoading: true,//控制Loading開關
		isActiveInfo: false,
		isTimesUp: false, //是否在8月20之前
		userLineInfo: {},
		userNTInfo: {},
		prizesList: {},
		token: "",
		allPoints: 0,
		addressData: addressData,
		liffID: process.env.VUE_APP_liffID,
		baseUri: process.env.VUE_APP_baseUri,
		hostUrl: process.env.VUE_APP_hostUrl,
		isInClient: process.env.VUE_APP_isInClient,
	},
	mutations: {
		timeCheck(state){
			// console.log("timeCheck")
			state.isTimesUp=true
		},
		infoToggle(state){
			// console.log("infoToggle")
			state.isActiveInfo=!state.isActiveInfo
		},
		loadingToggle(state,value){
			state.isActiveLoading=value
		},
		setUserLineInfo(state,{lineid,accesstoken,name,email,phone,picture,invite_code}){
			state.userLineInfo.lineid=lineid
			state.userLineInfo.accesstoken=accesstoken
			state.userLineInfo.name=name
			state.userLineInfo.email=email
			state.userLineInfo.phone=phone
			state.userLineInfo.picture=picture
			state.userLineInfo.invite_code=invite_code
		},
		setToken(state,token){
			state.token=token
		},
		setUserNTInfo(state,data){
			state.userNTInfo=data
		},
		setPrizes(state,data){
			state.prizesList=data
		},
		setAllPoints(state,data){
			state.allPoints=data
		},
	},
	actions: {
		getPrizes(context){
			// console.log("getPrizes")

			axios.get(context.state.hostUrl+"api/prizes")
				.then(function (response) {
					console.log(response)
					if(response.status===200){
						context.commit("setPrizes",response.data)
						context.dispatch("getUserNTInfo")
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		getUserNTInfo(context){
			// console.log("getUserNTInfo")

			axios.post(context.state.hostUrl+"api/user/query",{},
				{
					headers: {
						"Authorization": `Bearer ${context.state.token}`,
						"Accept": "application/json",
					},
				})
				.then(function (response) {
					console.log(response)
					if(response.status===200){
						context.commit("setUserNTInfo",response.data)
						axios.get(context.state.hostUrl+"api/total_point")
							.then((response)=>{
								if(response.status===200){
									context.commit("setAllPoints",response.data)

									setTimeout(() => {
										context.commit("loadingToggle",false)
									}, 2000);
								}
							})
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		userInit(context){
			// console.log("userInit")

			axios.post(context.state.hostUrl+"api/user/init",context.state.userLineInfo)
				.then(function (response) {
					console.log(response)
					if(response.status===200){
						let token=response.data.token
						context.commit("setToken",token)
						context.dispatch("getPrizes")
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			
		},
		initLineLiff(context){
			// console.log("initLineLiff")

			if (!context.state.liffID) {
				window.alert("LIFF ID error");
			} else {
				liff.init({
					liffId: context.state.liffID,
				}).then(function () {
					if (liff.isInClient()===JSON.parse(context.state.isInClient)) {
						alert("請回到手機 LINE APP 中進行操作");
					}else{
						if (!liff.isLoggedIn()) {
							liff.login({
								redirectUri: context.state.baseUri,
							});
						}else{

							let start = "2023-8-20 00:00";
							let now = new Date();
							let reg = new RegExp("-", "g");

							if (new Date(start.replace(reg, "/")) >= now) {
								context.commit("timeCheck")
							}

							liff.getProfile()
								.then(profile => {

									let lineid=profile.userId;
									let accesstoken=liff.getAccessToken()
									let name=profile.displayName;
									let picture=profile.pictureUrl;

									const idToken = liff.getDecodedIDToken();
									let phone=idToken.phone_number===undefined||idToken.phone_number===null ? "" : idToken.phone_number
									let email=idToken.email===undefined||idToken.email===null ? "" : idToken.email

									let invite_code=router.currentRoute.value.query.code===undefined ? "" :router.currentRoute.value.query.code

									if(invite_code){
										context.commit("setUserLineInfo",{lineid,accesstoken,name,email,phone,picture,invite_code})
									}else{
										context.commit("setUserLineInfo",{lineid,accesstoken,name,email,phone,picture})
									}

									context.dispatch("userInit")

								})
								.catch((err) => {
									console.log('initializeApp error', err);
									alert("伺服器忙碌中")
								});
						}
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	modules: {
	}
})
