<template lang="pug">
div
	transition(name="fadeLoading")
		Loading(v-show="isActiveLoading")
	Header
	Userinfo(v-if="$route.name!=='Share'&&$route.name!=='InvoiceChoose'&&$route.name!=='InvoiceForm'")
	router-view(v-model:prizeId.sync="prizeId" )
	transition(name="fade")
		PopupTime(v-model:isActivePopupTime.sync="isActivePopupTime" v-show="isActivePopupTime")
	transition(name="fade")
		info(v-model:isActiveInfo="isActiveInfo" v-show="isActiveInfo")
	transition(name="fade")
		.privacy-box(v-show="isShowPrivacy")
			.container
				p 為了提供更好的內容，我們使用相關網站技術來改善使用者體驗，也尊重用戶的隱私權，特別提出聲明。
				.btn-box
					a.btn-link(@click="infoToggle") >>了解詳情
					a.btn-close(@click="closePrivacy") #[img.icon(src="@/assets/images/ic_check-white.svg")]知道了
</template>

<script>
import Loading from '@/components/loading/index.vue'
import Header from '@/components/header/index.vue'
import Userinfo from '@/components/userinfo/index.vue'
import PopupTime from '@/components/popupTime/index.vue'
import Info from '@/components/info/index.vue'
// import VConsole from "vconsole";
import { nextTick } from "vue";

// const vConsole = new VConsole();
export default {
	computed: {
		isActiveLoading(){
			return this.$store.state.isActiveLoading
		},
		isActiveInfo(){
			return this.$store.state.isActiveInfo
		},
		userLineInfo(){
			return this.$store.state.userLineInfo
		},
		isTimesUp(){
			return this.$store.state.isTimesUp
		},
	},
	data(){
		return {
			isActivePopupTime: false,
			isShowPrivacy: false,
			prizeId: ""
		}
	},
	components: {
		Loading,
		Header,
		Userinfo,
		PopupTime,
		Info
	},
	watch: {
		isTimesUp(val){
			let vm = this;
			if(val){
				vm.isActivePopupTime= true
			}
		},
		"$route":{
			handler: function(val) {
				let vm = this;
				vm.isActivePopupTime= false
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		nextTick(() => {
			let vm = this;

			if(!localStorage.getItem("privacy")){
				vm.isShowPrivacy=true
			}

			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
			vm.$store.dispatch('initLineLiff')
		});
	},
	methods: {
		closePrivacy(){
			let vm = this;
			vm.isShowPrivacy=false
			localStorage.setItem("privacy", true)
		},
		infoToggle(){
			let vm=this
			vm.$store.commit('infoToggle')
			vm.closePrivacy()
		}
	},
}
</script>

<style lang="sass">
@import "@/assets/sass/_reset.sass"
@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
@import "@/assets/sass/_layout.sass"
</style>