<script>
import { nextTick } from "vue";
export default {
	name: "Header",
	data(){
		return{
			isActiveMenu: false,
			isEndInvoice: false
		}
	},
	watch: {
		$route(){
			let vm = this;
			vm.isActiveMenu = false;
		}
	},
	mounted() {
		nextTick(() => {
			let vm=this
			
			let start = "2023-9-16 00:00";
			let now = new Date();
			let reg = new RegExp("-", "g");

			if (new Date(start.replace(reg, "/")) <= now) {
				vm.isEndInvoice=true
			}
		})
	},
	methods: {
		menuToggle(){
			let vm=this
			// console.log("menuToggle")
			vm.isActiveMenu=!vm.isActiveMenu
		},
		infoToggle(target){
			let vm=this
			// console.log("infoToggle")
			vm.$store.commit('infoToggle')
			if(vm.isActiveMenu){
				vm.isActiveMenu=!vm.isActiveMenu
			}
		}
	},
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>